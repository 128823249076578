// What browser is being used?
navigator.sayswho = (() => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
})();

loadjs('/assets/scripts/matchMedia.js')

// loadjs('/assets/scripts/object-assign.js', 'objectAssign',
// {
//   success: () => {
//     window.ObjectAssign.polyfill()
//   }
// })

loadjs('/assets/scripts/jquery.js', 'jQuery')

if (document.querySelectorAll('[map-api-key]').length > 0) {
  let mapApiKey = document.querySelector('[map-api-key]').getAttribute('map-api-key')
  loadjs('//maps.google.com/maps/api/js?key=' + mapApiKey, 'googlemaps')
}

loadjs([
    '/assets/scripts/lazysizes-plugins/ls.bgset.js',
    '/assets/scripts/lazysizes-plugins/ls.respimg.min.js',
    '/assets/scripts/lazysizes-plugins/ls.attrchange.min.js',
    '/assets/scripts/lazysizes.js',
],
{
    async: false,
    success: () => {
      document.addEventListener('lazybeforeunveil', e => {
        let bg = e.target.getAttribute('data-bg');
        if (bg) {
          e.target.style.backgroundImage = 'url(' + bg + ')';
        }
        if (e.target.getAttribute('map-api-key')) {
          loadjs.ready('googlemaps',
          {
            success: () => {
              let mapElements = document.querySelectorAll('[address]');
              Array.prototype.forEach.call(mapElements, (el, i) => {
                let address = el.getAttribute('address');
                let lat = parseFloat(el.getAttribute('lat'));
                let lng = parseFloat(el.getAttribute('lng'));
                let map = new google.maps.Map(el, {
                  center: {
                    lat: lat,
                    lng: lng
                  },
                  zoom: 16
                });

                let marker = new google.maps.Marker({
                  position: {
                    lat: lat,
                    lng: lng
                  },
                  map: map,
                  title: 'MARKER_TITLE'
                });

                let infowindow = new google.maps.InfoWindow({
                  content: '<a href="https://www.google.com/maps/place/' + encodeURIComponent(address) + '" target="_blank">View on Google Maps</a>'
                });
                marker.addListener('click', () => {
                  infowindow.open(map, marker);
                });
              });
            }
          });
        }
      });
    }
});

loadjs.ready([
  // 'objectAssign',
  'jQuery'
],
{
  success: () => {
    // DOM Element is ready for modifications.
    $(document).ready(() => {
      // Stop placeholder links from affecting the url.
      let elements = document.querySelectorAll('[href="#"]');
      Array.prototype.forEach.call(elements, (el, i) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
        });
      });

      intellectualPropertySymbols();

      // Add current browser to document classes
      document.documentElement.className += ' ' + navigator.sayswho.toLowerCase().replace(' ', '-');

      // Match height
      // matchHeight('');

      // Initialize Mobile Nav
      initializeMobileNav();

      // Fallback in case font doesn't load fast enough and the window is still loading.
      setTimeout(() => {
        let el = document.getElementsByTagName('body')[0];
        let classToDel = 'fonts-loading';
        if (el.classList) {
          el.classList.remove(classToDel);
        } else {
          el.className = el.className.replace(new RegExp('(^|\\b)' + classToDel.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
      }, 2000);

      let timeout = false;
      let delay = 250;

      verticalAlignImageBlock();
      window.addEventListener('resize', () => {
        clearTimeout(timeout);
        timeout = setTimeout(verticalAlignImageBlock, delay);
      });
    });

    // When the browser window is resized.
    // $(window).resize(() => {});

    // When the device changes orientation.
    // $(window).on('orientationchange', (e) => {});

    initializeFormNameCombiner();
    let inputs = document.querySelectorAll('form input');
    let textareas = document.querySelectorAll('form textarea');

    Object.keys(inputs).forEach(i => {
      let input = inputs[i];
      input.addEventListener('blur', validateField);
      input.addEventListener('keyup', validateField);
    });
    Object.keys(textareas).forEach(i => {
      let textarea = textareas[i];
      textarea.addEventListener('blur', validateField);
      textarea.addEventListener('keyup', validateField);
    });
    let submits = document.querySelectorAll('form [href="#submit"]');
    Object.keys(submits).forEach((i) => {
      let submit = submits[i];
      submit.addEventListener('click', function (e) { // Cannot be an arrow function because of $(this) usage
        e.preventDefault();
        var form = $(this).closest('form')[0]
        if (form && form.checkValidity()) {
          $(this).closest('form').submit();
        } else {
          let inputs = document.querySelectorAll('input');
          let textareas = document.querySelectorAll('textarea');
          let blurevent = document.createEvent("HTMLEvents");
          blurevent.initEvent("blur", true, true);
          blurevent.eventName = "blur";

          Object.keys(Object.assign(inputs, textareas)).forEach(i => {
            let input = inputs[i];
            input.dispatchEvent(blurevent);
          });

          let firstErrorEl = document.querySelector('.error').getBoundingClientRect();
          if (firstErrorEl) {
            window.scrollTo(window.scrollX + firstErrorEl.left, window.scrollY + firstErrorEl.top);
          }
        }
      });
    });
  }
});

if (document.getElementsByClassName('slider').length > 0) {
  loadjs([
      '/assets/scripts/slick.js',
      '/assets/vendor/slick-carousel/slick.css'
    ], {
    success: () => {
      // Slick Carousel stuff
      $('.slider').slick({
        dots: true,
        adaptiveHeight: true,
        responsive: [{
          breakpoint: 480,
          settings: {
            arrows: false
          }
        }]
      });
    }
  });
}

if (document.getElementsByClassName('form-block').length > 0) {
    loadjs([
        '/assets/scripts/jquery.matchHeight.js'
    ], {
        success: () => {
            $('.form-block').each(function() {
                $(this).addClass('js');
                var button = $('button.button', $(this)).clone();
                $('.form-block__column', $(this)).matchHeight({
                    byRow: true
                });
                var copiedButton = $('.form-block__copy', $(this)).append(button);
                var $form = $('form', $(this));
                copiedButton.click(function () {
                    $form.submit();
                });
            });
        }
    });
}

// Inlcude jQuery placeholder on pages that have the placeholder attribute.
if (document.querySelectorAll('[placeholder]').length > 0) {
  loadjs.ready('jQuery', {
      success: () => {
          loadjs('/assets/scripts/jquery.placeholder.js')
      }
  })
}

// Include splitview javascript on pages with split views
if (document.querySelectorAll('.split-view').length > 0) {
  loadjs('/assets/scripts/splitview.js');
}

// include dropkick dropdowns
if (document.querySelectorAll('select').length > 0) {
  loadjs('/assets/scripts/dropkick.min.js', {
      success: () => {
        let selects = document.querySelectorAll('select');
        Object.keys(selects).forEach(i => {
          let select = selects[i];
          new Dropkick(select);
        });
      }
  });
}

if (document.getElementsByClassName('council-members__list').length > 0) {
    loadjs([
        '/assets/scripts/jquery.js'
    ], {
        success: () => {
            loadjs([
                '/assets/scripts/jquery.matchHeight.js'], {
                success: () => {
                    $('.council-members__list .council-members__item small').matchHeight({
                        byRow: true
                    });
                    $('.council-members__list .council-members__item p').matchHeight({
                        byRow: true
                    });
                }
            });
        }
    });
}

if (document.getElementsByClassName('news-listing').length > 0) {
    loadjs([
        '/assets/scripts/jquery.js'
    ], {
        success: () => {
            loadjs([
                '/assets/scripts/jquery.matchHeight.js'], {
                success: () => {
                    $('.news-listing .news-listing__box').matchHeight({
                        byRow: true
                    });
                }
            });
        }
    });
}

// Inlcude Video.js on pages that use video-js
if (document.getElementsByClassName('video-js').length > 0) {
  loadjs([
    '/assets/scripts/video.js',
    '/assets/vendor/video-js/video-js.css',
    '/assets/styles/video.css',
  ]);
}

function verticalAlignImageBlock() {
  let imageEls = document.getElementsByClassName('image');
  Object.keys(imageEls).forEach(i => {
    let el = imageEls[i];
    let figure = el.querySelector('.image__wrapper .split-view') ? el.querySelector('.image__wrapper .split-view') : el.querySelector('.image__wrapper > figure');
    if (figure) {
      let div
      if (figure.classList.contains('split-view')) {
        div = el.querySelector('.image__wrapper > div + div');
      } else {
        div = el.querySelector('.image__wrapper > div');
      }
      if (matchMedia('screen and (min-width: 61.1875em)').matches) {
        let figureHeight = figure.offsetHeight;
        let divHeight = div.offsetHeight;
        if (figureHeight > divHeight) {
          div.style.marginTop = ((figureHeight - divHeight) / 2) + 'px';
        } else if (figureHeight != divHeight) {
          figure.style.marginTop = ((divHeight - figureHeight) / 2) + 'px';
        }
      } else {
        div.style.marginTop = '';
        figure.style.marginTop = '';
      }
    }
  });
  let feedItemEls = document.querySelectorAll('.feed__items:not([class*="-col"]) .feed__item--image');
  Object.keys(feedItemEls).forEach(i => {
    let el = feedItemEls[i];
    let figure = el.querySelector(':scope > figure');
    let div = el.querySelector(':scope > div');
    if (matchMedia('screen and (min-width: 61.1875em)').matches) {
      let figureHeight = figure.offsetHeight;
      let divHeight = div.offsetHeight;
      if (figureHeight > divHeight) {
        div.style.marginTop = ((figureHeight - divHeight) / 2) + 'px';
      } else if (figureHeight != divHeight) {
        figure.style.marginTop = ((divHeight - figureHeight) / 2) + 'px';
      }
    } else {
      div.style.marginTop = '';
      figure.style.marginTop = '';
    }
  });
}

function matchHeight(selector) {
  let heights = {};
  let elements = document.querySelectorAll(selector);
  Array.prototype.forEach.call(elements, (el, i) => {
    el.style.height = '';
    if (heights[el.offsetTop] === undefined || el.offsetHeight > heights[el.offsetTop]) {
      heights[el.offsetTop] = el.offsetHeight;
    }
  });
  Array.prototype.forEach.call(elements, (el, i) => {
    el.style.height = heights[el.offsetTop] + 'px';
  });
}

function initializeMobileNav() {
  $('header .menu-toggle').on('click', function (e) {
    if (matchMedia('screen and (max-width: 1049px)').matches) {
      if ($(this).hasClass('back')) {
        $(this).removeClass('back');
        $('nav').addClass('active');
        $('nav .active').removeClass('active');
      } else if ($(this).hasClass('close')) {
        $(this).removeClass('close');
        $('nav').removeClass('active');
      } else {
        $('nav').addClass('active');
        $(this).addClass('close');
      }
    }
  });
  $('nav > div > a').on('click', function (e) {
    if (matchMedia('screen and (max-width: 1049px)').matches) {
      if (!$(this).parent().hasClass('active')) {
        e.preventDefault();
        $('header .menu-toggle').addClass('back');
        $('nav').removeClass('active');
        $(this).closest('div').addClass('active');
      }
    }
  });
}

function intellectualPropertySymbols() {
  $("h1,h2,h3,h4,h5,h6,p,a,span,li").each(function () {
    let html = $(this).html();
    let re = new RegExp(/&reg;|®|&trade;|™|&copy;|©/gi)
    if (re.test(html)) {
      $(this).html(
        html
          .replace(/&reg;/gi, '<sup>&reg;</sup>')
          .replace(/®/gi, '<sup>&reg;</sup>')
          .replace(/&trade;/gi, '<sup>&trade;</sup>')
          .replace(/™/gi, '<sup>&trade;</sup>')
          .replace(/&copy;/gi, '<sup>&copy;</sup>')
          .replace(/©/gi, '<sup>&copy;</sup>')
          .replace(/(<sup>)+/gi, '<sup>')
          .replace(/(<\/sup>)+/gi, '</sup>')
      );
    }
  });
}

function initializeFormNameCombiner() {
  $('input[name="fromName"],input[name="lastName"]').on('blur change keyup', e => {
    $('input[name="message[Name]"]')[0].value = ($('input[name="fromName"]')[0].value + " " + $('input[name="lastName"]')[0].value).trim();
  });
  $('input[name="fromEmail"]').on('blur change keyup', function (e) {
    $('input[name="message[Email]"]')[0].value = $(this)[0].value;
  });
}

function validateField() {
  if (this.checkValidity()) {
    this.classList.remove('error');
  } else {
    this.classList.add('error');
  }
}
